.header{
    background-color: white;
    border-bottom: 1px solid lightgrey;
    border-collapse: collapse;
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
    width: 84%;
    margin-left: 16%;
    min-height: 64px;
    font: 500 20px/32px Roboto, "Helvetica Neue", sans-serif;;
}
.secondDiv{
    display: flex;
    box-sizing: border-box;
    padding: 0 16px;
    width: 100%;
    flex-direction: row;
    align-items: center;
    white-space: nowrap;
    height: 64px;
}
.menuSpan{
    margin-top: 5px;
    cursor: pointer;
}
.menuButton{
    padding: 0;
    min-width: 0;  
}
.companyName{
    padding-left: 10px;
}

