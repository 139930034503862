.expertsWrapper {
  /* width: 100%;
  height: 100%; */
  background-color: white;
  padding: 1rem;
  font: 400 14px/20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}
.wrapperForSideBarMargins {
  margin-left: 18%;
  margin-top: 1%;
  width: 80%;
}
.expertsContainer {
  /* width: 100%;
  height: 100%; */
  transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
  display: block;
  position: relative;
  padding: 16px;
  border-radius: 4px;
  background: #fff;
  color: rgba(0, 0, 0, 0.87);
  box-shadow: 0px 7px 8px -4px rgb(0 0 0 / 20%),
    0px 12px 17px 2px rgb(0 0 0 / 14%), 0px 5px 22px 4px rgb(0 0 0 / 12%);
}

.expertsHeaderRow {
  height: 56px;
  color: white !important;
  font-weight: bold !important;
  background-color: #d3d641 !important;
}
.expertRow {
  text-align: left;
  height: 48px;
  display: flex;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: rgba(0, 0, 0, 0.12);
}
.expertRowHover:hover {
  cursor: pointer !important;
  background-color: rgba(60, 195, 221, 0.1) !important;
  color: black !important;
  font-weight: bold !important;
}
.expertRow .col {
  padding-left: 24px;
  align-items: center;
  display: flex;
  justify-content: center;
}
.expertRow .col:nth-child(1) {
  width: 5%;
}
.expertRow .col:nth-child(2) {
  width: 20%;
}
.expertRow .col:nth-child(3) {
  width: 20%;
}
.expertRow .col:nth-child(4) {
  width: 10%;
}
.expertRow .col:nth-child(5) {
  width: 10%;
}
.expertRow .col:nth-child(6) {
  width: 20%;
}
.expertRow .col:nth-child(7) {
  width: 15%;
  align-items: center;
  text-align: center;
}

.searchRow {
  display: flex;
  height: 65px;
}
.plusIcon {
  width: 20px;
  color: #3f51b5;
  margin-right: 5px;
  cursor: pointer;
}
.searchRow .col:nth-child(1) {
  width: 95%;
}
.searchRow .col:nth-child(2) {
  width: 5%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.searchForm {
  width: 100%;
}
.expertDivider {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
  display: block;
  margin: 0;
  border-top-width: 1px;
  border-top-style: solid;
  border-top-color: rgba(0, 0, 0, 0.12);
}
.expertName {
  font: 400 24px/32px "Roboto", "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
  text-align: center;
}
.deleteExpertButton {
  background-color: #f44336 !important;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%),
    0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%) !important;
  color: #fff !important;
  font-family: "Roboto", "Helvetica Neue", sans-serif !important;
  font-size: 14px !important;
  font-weight: 550 !important;
  text-transform: unset !important;
}
.expertFirstCard .row {
  display: flex;
  color: #800000;
  font: 400 14px/20px "Roboto", "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.expertFirstCard .row:first-child .col:nth-child(1) {
  width: 90%;
  font-size: 24px;
  font-weight: 500;
  color: black;
}

.expertFirstCard .row:first-child .col:nth-child(2) {
  display: flex;
  justify-content: flex-end;
  width: 10%;
}
.expertFirstCard .row:not(:first-child) .col:nth-child(1) {
  width: 10%;
  margin-right: 10px;
  margin-bottom: 12px;
  font-weight: 700;
}

.expertFirstCard .row:not(:first-child) .col:nth-child(2) {
  width: 90%;
}

.patientContainer {
  box-shadow: none !important;
  padding-top: 0 !important;
}
.accordionTitle {
  padding-left: 16px;
  margin: 0;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: 0.00938em;
  font-size: 15px !important;
}
.createExpertButton {
  background-color: #3f51b5 !important;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%),
    0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%) !important;
  margin-bottom: 0 !important;
  margin-top: 15px !important;
  color: #fff !important;
  font-family: Roboto, "Helvetica Neue", sans-serif !important;
  font-size: 13px !important;
  font-weight: 600 !important;
  width: 100%;
}
.errorMessage {
  margin-top: 10px;
  margin-bottom: 0px;
  color: red;
  font-size: 15px !important;
  font-weight: 550;
  min-height: 20px;
}

/* CSS */
.button-43 {
  background-color: #d3d641 !important;
  border-radius: 0.5rem;
  box-sizing: border-box;
  color: #000 !important;
  display: flex;
  font-size: 16px;
  justify-content: center;
  padding: 1rem 1.75rem;
  text-decoration: none;
  width: 100%;
  border: 2px solid #d3d641 !important;
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  transition: all 2s !important;
}

/* CSS */
.button-42 {
  background-color: transparent !important;
  border-radius: 0.5rem;
  box-sizing: border-box;
  color: #000 !important;
  display: flex;
  font-size: 12px;
  justify-content: center;
  padding: 0.5rem 1rem;
  text-decoration: none;
  width: 100%;
  border: #d3d641 2px solid !important;
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  transition: all 1s;
}
/* CSS */
.button-41 {
  background-color: #15ae80 !important;
  border-radius: 0.5rem;
  box-sizing: border-box;
  color: #ffffff !important;
  display: flex;
  font-size: 12px;
  justify-content: center;
  padding: 0.5rem 1rem;
  text-decoration: none;
  width: 100%;
  border: 0;
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-disabled {
  background-color: #A9A9A9 !important; /* A grey color */
  color: #808080 !important; /* A darker grey color for the text */
  cursor: not-allowed !important;
  border: 2px solid #A9A9A9 !important;
}
