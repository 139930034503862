.patientsList {
  margin-left: 18%;
  margin-top: 2%;
  width: 82% !important;
}
.patientsListview {
  width: 98% !important;
  border-spacing: 0;
  border-radius: 5px;
  box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%),
    0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%);
}
.filterHeader {
  width: 98% !important;
  background-color: #d3d641 !important;
  height: 40px;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%),
    0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
  border-radius: 4px;
  cursor: pointer;
  padding-top: 20px;
}
.collapse {
  width: 98% !important;
  height: 120px;
  border-radius: 4px !important;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%),
    0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%) !important;
}
.filterWord {
  padding-left: 24px;
  padding-top: 18px;
  font-weight: bold;
  color: white;
}
.filterIndicator {
  color: white;
  padding-left: 90%;
}
.filterButton {
  margin-left: 40%;
}
.filterButtonWord {
  width: 45% !important;
  height: 40px;
  margin-left: 30%;
  text-align: center;
  border-radius: 5px;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%),
    0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
  color: black !important;
  font-weight: bold !important;
  text-transform: none !important;
}
.filterButtonWord:hover {
  outline: none;
}
.listviewHeaders {
  color: white;
  background-color: #d3d641 !important;
  height: 56px;
}
.listviewDataRow {
  height: 48px;
}
.listviewDataRow:hover {
  cursor: pointer !important;
  background-color: rgba(60, 195, 221, 0.1) !important;
  font-weight: bold !important;
}
.listviewDataCell {
  text-align: center;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: rgba(0, 0, 0, 0.12);
}
.searchRow {
  margin-bottom: 20px;
  margin-top: 5px;
}
.searchForm {
  width: 98% !important;
}

.pagination {
  display: flex;
  justify-content: center;
  padding: 25px;
  background: #000;
  margin: 25px 0;
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
