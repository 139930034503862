.sidebar{
    width: 16%;
    background-color: #fff;
    border-right: solid 1px rgba(0,0,0,.12);
    position: absolute;
    top: 0;
    bottom: 0;
    padding-top: 8px;
    position: fixed;
}
.section{
    height: 48px;
    display: flex;
    align-items: center;
    padding: 0 16px;
    cursor: pointer;
}
.section:hover{
    background-color: rgba(135, 141, 141, 0.1) !important;
}
.moduleName{
    font-weight: 500;
}
.iconSidebar{
    color: #D3D641;
    margin-right: 15px;
    width: 24px;
    height: 24px;
}