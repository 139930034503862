.loginWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.loginForms {
  margin-bottom: 2rem !important;
}
.loginFormsInput {
  font-size: 15px !important;
  left: -12px !important;
}
.loginCard {
  flex-direction: column;
  justify-content: center;
  display: flex;
  align-items: center;
  padding: 16px;
  background-color: #fff;
}
.errorMessage {
  margin-top: 0;
  margin-bottom: 1rem;
  color: red;
  font-size: 15px !important;
}
.loginButton {
  background-color: #3f51b5 !important;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%),
    0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%) !important;
  margin-bottom: 0 !important;
  color: #fff !important;
  font-family: Roboto, "Helvetica Neue", sans-serif !important;
  font-size: 13px !important;
  font-weight: 600 !important;
  width: 100%;
}
.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
