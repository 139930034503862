.barChartDivLeft {
  width: 39%;
  margin-top: 2%;
  margin-left: 18%;
  float: left;
  border-radius: 5px;
  box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%),
    0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%);
}
.barChartDivRight {
  width: 39%;
  margin-left: 38.5%;
  border-radius: 5px;
  box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%),
    0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%);
}
.barChartDivRight2 {
  width: 39%;
  margin-left: 38.5%;
  border-radius: 5px;
  box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%),
    0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%);
}
.extraBarChartDiv {
  margin-top: 2%;
}
.icon {
  margin-left: 50%;
  margin-top: 2%;
}
.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
