.patientBody{
    margin-left: 12%;
}
.patientName{
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
    font-family: Roboto, "Helvetica Neue", sans-serif;
    text-align: center;
}
.patientInfoCard{
    box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
    padding: 16px;
    border-radius: 4px;
    width: 82%;
    margin-left: auto;
    margin-right: auto;
}
.patientInfo{
    font-size: 24px;
    font-weight: 500;
}
.deleteButton{
    background-color: #f44336 !important;
    box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
    color: white !important;
    text-transform: none !important;
    font-weight: 700 !important;
    margin-left: 90% !important;
    margin-top: -45px !important;
}
.infoDiv{
    display: flex;
}
.infoTitle{
    color: #800000;
    max-width: 30% !important;
}
.infoData{
    color: #800000;
    margin-left: 10px;
}
.horizontalLine{
    border-top-color: rgba(0,0,0,.12);
    border-top-width: 1px;
    border-top-style: solid;
    margin-top: 15px;
    width: 84%;
}
.visitDiv{
    background-color: #ffbf68;
    margin-bottom: 1%;
    box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
    flex-direction: column;
    box-sizing: border-box;
    display: flex;
    border-radius: 4px;
    width:84%;
    margin-left: auto;
    margin-right: auto;
}
.visitDiv:hover{
    outline: none;
}
.visitMidDiv{
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 24px;
    border-radius: inherit;
    height: 48px;
    position: relative;
}
.visitPanel{
    display: flex;
    flex: 1;
    flex-direction: row;
    overflow: hidden;
    font-weight: 500;
    color: #2e3d50;
    font-size: 1.2rem;
}
.patientTable{
    border-spacing: 0 !important;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 1.5%;
    margin-bottom: 1.5%;
}
.headers{
    height: 56px;
    background-color: #800000 !important;
    color: white;
    width: 80%;
}
.questionNumber{
    width: 10%;
}
.questions{
    width: 60%;
    text-align: left;
    padding-left: 50px;
}
.answers{
    width: 30%;
    text-align: center;
    padding-right: 35px;
}
.body{
    background-color: white;
}
.dataRow{
    height:48px;
    cursor: pointer;
}
.dataRow:hover{
    background-color: rgba(60, 195, 221, 0.1) ;
}
.questionNumberData{
    text-align: center;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: rgba(0,0,0,.12);
}
.questionData{
    padding-left: 50px;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: rgba(0,0,0,.12);
}
.answerData{
    padding-right: 35px;
    text-align: center;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: rgba(0,0,0,.12);
}